import React from "react"
import { Label, Flex, Box } from "../system"
import { GatsbyImage } from "gatsby-plugin-image"

const Post = ({ title, image, date }) => {
  return (
    <Flex
      flexDirection="column"
      width={["90vw", "40vw", "27vw"]}
      alignItems="flex-start"
    >
      <Box width="100%">
        <GatsbyImage image={image.asset.gatsbyImageData} />
      </Box>
      <Label textAlign="left" mt={2}>
        {title}
      </Label>
    </Flex>
  )
}

export default Post
