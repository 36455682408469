import React from "react"
import { graphql } from "gatsby"
import { Grid, Flex, Title, Label } from "../components/system"

import Layout from "../components/layout"
import Post from "../components/Post"

const Press = ({ data }) => {
  return (
    <Layout dark pt={6}>
      <Flex flexDirection="column" pt={150}>
        <Label fontSize={1} color="faded">
          Press
        </Label>
        <Title fontSize={[6, 7]} mb={5} color="secondary">
          Articles
        </Title>
        <Flex>
          <Grid
            gridGap={50}
            gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
            mx={[2, 4, 4]}
            mb={5}
          >
            {data &&
              data.allSanityPost.nodes.map((post, key) => (
                <a href={post.link} target="_blank" rel="noreferrer">
                  <Post title={post.title} image={post.image} />
                </a>
              ))}
          </Grid>
        </Flex>
      </Flex>
    </Layout>
  )
}

export default Press

export const pageQuery = graphql`
  query posts {
    allSanityPost {
      nodes {
        id
        title
        link
        image {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`
